import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Grid, Card, CardContent, CardMedia, Pagination } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchAndScan from './SearchAndScan';

export default function CustomizedTables() {
  const [assets, setAssets] = useState([]); 
  const [filteredAssets, setFilteredAssets] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1); 
  const rowsPerPage = 60; 

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://dbaac.dodaydream.net/api/fixed_assets/all');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setAssets(data);
        setFilteredAssets(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (searchTerm, searchTermOld) => {
    if (searchTerm || searchTermOld) {
      const filtered = assets.filter(asset =>
        (asset.Inventory_ID?.toLowerCase().includes(searchTerm.toLowerCase()) || 
         asset.Inventory_ID_OLD?.toLowerCase().includes(searchTermOld.toLowerCase()))
      );

      setFilteredAssets(filtered);

      // Navigate to detail page if only one result
      if (filtered.length === 1) {
        navigate(`/asset/${filtered[0].id}`);
      }

      // Reset to first page on new search
      setPage(1);
    } else {
      setFilteredAssets(assets);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const displayedAssets = filteredAssets.length > 0 
    ? filteredAssets.slice((page - 1) * rowsPerPage, page * rowsPerPage)
    : [];

  return (
    <Box p={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h6">Total Assets: {assets.length}</Typography>
        <Typography variant="h6">Filtered Assets: {filteredAssets.length}</Typography>
      </Box>

      <SearchAndScan onSearch={handleSearch} />

      <Grid container spacing={3}>
        {displayedAssets.length > 0 ? (
          displayedAssets.map((asset) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={asset.id}>
              <Card onClick={() => navigate(`/asset/${asset.id}`)} style={{ cursor: 'pointer', height: '100%' }}>
                <CardMedia
                  component="img"
                  height="280"
                  width="100%"
                  image={asset.Picture_1 ? `https://dbaac.dodaydream.net/images/assets/${asset.Picture_1}` : 'path_to_default_image.jpg'}
                  alt={asset.Assetname || 'No Image Available'}
                />
                <CardContent>
                  <Typography variant="h6" component="div">
                    {asset.Assetname || 'Unknown Asset'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Inventory ID: {asset.Inventory_ID || '-'}
                  </Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                    <Typography variant="body2" color="text.secondary">
                    Check By User {asset.usercheck === 'Yes' ? (
                        <CheckCircleIcon style={{ color: 'green' }} />
                      ) : (
                        <CancelIcon style={{ color: 'red' }} />
                      )}
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <Typography variant="body2" color="text.secondary" mr={1}>
                        Check By AC:
                      </Typography>
                      {asset.Check_By_AC === 'Yes' ? (
                        <CheckCircleIcon style={{ color: 'green' }} />
                      ) : (
                        <CancelIcon style={{ color: 'red' }} />
                      )}
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                    <Typography variant="body2" color="text.secondary">
                      STATUS: {asset.STATUS || '-'}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Tag: {asset.Status_Tag || '-'}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="h6" align="center" sx={{ width: '100%' }}>
            No results found
          </Typography>
        )}
      </Grid>

      <Box display="flex" justifyContent="center" mt={3}>
        {filteredAssets.length > rowsPerPage && (
          <Pagination
            count={Math.ceil(filteredAssets.length / rowsPerPage)}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        )}
      </Box>
    </Box>
  );
}