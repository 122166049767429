import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Alert } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';  // เพิ่ม Link
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../redux/userSlice';

const Login = () => {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.user);

  // Redirect to homepage if already authenticated
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
        const response = await fetch('https://dbaac.dodaydream.net/api/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
        });

        const data = await response.json();

        if (response.ok) {
            localStorage.setItem('token', data.token);  // Store the token
            dispatch(setUser({ user: data.user, token: data.token }));
            navigate('/');  // Redirect to the homepage or any other protected page
        } else {
            setError('Login failed. Please check your username and password.');
        }
    } catch (error) {
        console.error('Error:', error);
        setError('An error occurred. Please try again later.');
    }
  };


  return (
    <Container maxWidth="xs">
      <Typography variant="h4" gutterBottom>Login</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Username"
          name="username"
          fullWidth
          margin="normal"
          required
          onChange={handleChange}
        />
        <TextField
          label="Password"
          name="password"
          type="password"
          fullWidth
          margin="normal"
          required
          onChange={handleChange}
        />
        {error && <Alert severity="error">{error}</Alert>}
        <Button variant="contained" color="primary" type="submit" fullWidth style={{ marginTop: '16px' }}>
          Login
        </Button>
        <Typography variant="body2" align="center" style={{ marginTop: '16px' }}>
          <Link to="/forgot-password">Forgot Password?</Link>  {/* ลิงก์ไปยังหน้าลืมรหัสผ่าน */}
        </Typography>
      </form>
    </Container>
  );
};

export default Login;
