// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Navbar from './components/Navbar';
import Footer from './components/Footer'; // Import the Footer component
import Signup from './components/Signup';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import MyAccount from './components/MyAccount';
import CompanyList from './components/CompanyList';
import CompanyForm from './components/CompanyForm';
import ProtectedRoute from './components/ProtectedRoute';
import ProtectedRouteSuperAdmin from './components/ProtectedRouteSuperAdmin';
import CustomizedTables from './components/CustomizedTables';
import NoCheckAssets from './components/NoCheckAssets';
import YesCheckAssets from './components/YesCheckAssets';
import AssetDetail from './components/AssetDetail';
import EditAsset from './components/EditAsset';
import AddAsset from './components/AddAsset';
import UserManagement from './components/UserManagement';
import EditUser from './components/EditUser';
import NotFound from './components/NotFound';
import { setUser } from './redux/userSlice';

function App() {
  const { isAuthenticated } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const fetchUserData = async () => {
        const response = await fetch('https://dbaac.dodaydream.net/api/employees/me', {
          headers: {
            'Authorization': `Bearer ${token}`,  // Bearer Token
          },
        });
        if (response.ok) {
          const userData = await response.json();
          dispatch(setUser({ user: userData, token }));
        } else {
          console.error('Failed to fetch user data', response.statusText);
        }
      };
      fetchUserData();
    }
  }, [dispatch]);

  return (
    <Router>
      {isAuthenticated && <Navbar />}
      <div style={{ padding: '20px' }}>
        <Routes>
          {/* Public routes */}
          <Route path="/login" element={!isAuthenticated ? <Login /> : <Navigate to="/" />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/signup" element={!isAuthenticated ? <Signup /> : <Navigate to="/" />} />

          {/* Protected routes */}
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<CustomizedTables />} />
            <Route path="/customizedtables" element={<CustomizedTables />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/nocheck" element={<NoCheckAssets />} />
            <Route path="/yescheck" element={<YesCheckAssets />} />
            <Route path="/asset/:id" element={<AssetDetail />} />
            <Route path="/edit/:id" element={<EditAsset />} />
            <Route path="/add-asset" element={<AddAsset />} />
            <Route path="/settings/users" element={<UserManagement />} />
            <Route path="/settings/users/add" element={<EditUser />} />
            <Route path="/settings/users/edit/:id" element={<EditUser />} />
          </Route>

          {/* Protected superadmin routes */}
          <Route element={<ProtectedRouteSuperAdmin />}>
            <Route path="/companies" element={<CompanyList />} />
            <Route path="/companies/add" element={<CompanyForm />} />
            <Route path="/companies/edit/:id" element={<CompanyForm />} />
          </Route>

          {/* Fallback route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer /> {/* Include the Footer component */}
    </Router>
  );
}

export default App;
