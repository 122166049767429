// ProtectedRouteSuperAdmin.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRouteSuperAdmin = () => {
  const { isAuthenticated, user } = useSelector((state) => state.user);

  // ถ้าไม่ได้เข้าสู่ระบบหรือ role ไม่ใช่ superadmin
  if (!isAuthenticated || user?.role !== 'superadmin') {
    return <Navigate to="/" replace />;  // Redirect ไปหน้าแรก
  }

  return <Outlet />;  // ถ้าเป็น superadmin, ให้แสดงเส้นทางภายใน
};

export default ProtectedRouteSuperAdmin;
