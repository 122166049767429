import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbarContainer, useGridApiContext, useGridApiRef } from '@mui/x-data-grid';
import { createSvgIcon } from '@mui/material/utils';
import { Button, Box, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import SearchAndScan from './SearchAndScan';
import { utils, writeFile } from 'xlsx'; // เพิ่มไลบรารี xlsx สำหรับการ export เป็น XLSX

// สร้าง Icon สำหรับปุ่ม Export
const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  'SaveAlt',
);

// Custom Toolbar สำหรับ Export CSV และ XLSX
function CustomToolbar() {
  const apiRef = useGridApiContext();

  // Function สำหรับการ export CSV โดยใช้ UTF-8 และตามคอลัมน์ที่แสดง
  const handleExportCSV = () => {
    const visibleColumns = apiRef.current.getVisibleColumns(); // ดึงคอลัมน์ที่ถูกแสดงอยู่
    const rows = Array.from(apiRef.current.getRowModels().values()); // แปลง rowModels เป็น array

    // สร้าง header จากคอลัมน์ที่แสดง
    const csvContent = [
      '\uFEFF', // บังคับให้ไฟล์เป็น UTF-8 BOM เพื่อให้รองรับภาษาไทย
      visibleColumns.map((col) => col.field).join(','), // สร้าง header จากฟิลด์ของคอลัมน์ที่แสดง
      ...rows.map((row) =>
        visibleColumns.map((col) => row[col.field]).join(',') // สร้างแต่ละแถวตามคอลัมน์ที่แสดง
      ),
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'AssetList.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Function สำหรับการ export XLSX โดยใช้คอลัมน์ที่แสดงอยู่
  const handleExportXLSX = () => {
    const visibleColumns = apiRef.current.getVisibleColumns(); // ดึงคอลัมน์ที่ถูกแสดงอยู่
    const rows = Array.from(apiRef.current.getRowModels().values()); // แปลง rowModels เป็น array

    // เตรียมข้อมูลในรูปแบบ JSON ที่ต้องการใช้กับ XLSX
    const dataToExport = rows.map((row) =>
      visibleColumns.reduce((acc, col) => {
        acc[col.field] = row[col.field]; // สร้าง object โดยใช้ข้อมูลเฉพาะจากคอลัมน์ที่แสดง
        return acc;
      }, {})
    );

    const ws = utils.json_to_sheet(dataToExport); // สร้าง worksheet จากข้อมูลที่เตรียมไว้
    const wb = utils.book_new(); // สร้าง workbook ใหม่
    utils.book_append_sheet(wb, ws, 'Sheet1'); // เพิ่ม worksheet เข้าไปใน workbook
    writeFile(wb, 'AssetList.xlsx'); // บันทึกไฟล์ xlsx
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" size="small" startIcon={<ExportIcon />} onClick={handleExportCSV}>
        Export CSV
      </Button>
      <Button color="primary" size="small" startIcon={<ExportIcon />} onClick={handleExportXLSX}>
        Export XLSX
      </Button>
    </GridToolbarContainer>
  );
}



export default function NoCheckAssets() {
  const [assets, setAssets] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userCheckNoCount, setUserCheckNoCount] = useState(0);
  const [userCheckYesCount, setUserCheckYesCount] = useState(0);
  const apiRef = useGridApiRef();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [assetsResponse, companiesResponse] = await Promise.all([
          fetchWithToken('https://dbaac.dodaydream.net/api/fixed_assets/all'),
          fetchWithToken('https://dbaac.dodaydream.net/api/companies'),
        ]);

        if (!assetsResponse.ok || !companiesResponse.ok) {
          throw new Error('Network response was not ok');
        }

        const assetsJson = await assetsResponse.json();
        const companiesJson = await companiesResponse.json();

        setCompanies(companiesJson);

        const companyIdToDetails = {};
        companiesJson.forEach((company) => {
          companyIdToDetails[company.company_id] = {
            org: company.org || '',
            orgname: company.orgname || '',
            orgname_th: company.orgname_th || '',
          };
        });

        const processedAssets = assetsJson.map((asset, index) => {
          const companyDetails = companyIdToDetails[asset.Company] || {
            org: 'Unknown',
            orgname: 'Unknown',
            orgname_th: 'Unknown',
          };

          return {
            ...asset,
            org: companyDetails.org,
            orgname: companyDetails.orgname,
            orgname_th: companyDetails.orgname_th,
            id: asset.id || asset.Inventory_ID || `${asset.Company}-${index}`,
          };
        });

        setAssets(processedAssets);

        const noCheckCount = processedAssets.filter(asset => asset.usercheck === 'No').length;
        const yesCheckCount = processedAssets.filter(asset => asset.usercheck === 'Yes').length;

        setUserCheckNoCount(noCheckCount);
        setUserCheckYesCount(yesCheckCount);

        let dynamicColumns = [];
        if (processedAssets.length > 0) {
          dynamicColumns = [
            { field: 'org', headerName: 'Org', minWidth: 150, flex: 1, sortable: true },
            { field: 'orgname', headerName: 'Orgname', minWidth: 150, flex: 1, sortable: true },
            { field: 'orgname_th', headerName: 'Orgname Th', minWidth: 150, flex: 1, sortable: true },
          ];

          const otherKeys = Object.keys(processedAssets[0]).filter(
            key => !['org', 'orgname', 'orgname_th', 'Company'].includes(key)
          );

          otherKeys.forEach((key) => {
            dynamicColumns.push({
              field: key,
              headerName: key.replace(/_/g, ' '),
              minWidth: 150,
              flex: key.length > 10 ? 2 : 1,
              sortable: true,
            });
          });
        }

        setColumns(dynamicColumns);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchWithToken = async (url, options = {}) => {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('Authentication token is missing.');

      const headers = {
        Authorization: `Bearer ${token}`,
        ...options.headers,
      };

      const response = await fetch(url, { ...options, headers });
      return response;
    };

    fetchData();
  }, []);

  const handleRowClick = (params) => {
    navigate(`/asset/${params.id}`);
  };

  const handleSearch = (searchTerm) => {
    if (searchTerm) {
      const filtered = assets.filter(asset =>
        asset.Inventory_ID?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        asset.Inventory_ID_OLD?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setAssets(filtered);
    } else {
      setAssets(assets);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const totalAssets = userCheckNoCount + userCheckYesCount;
  const userCheckPercentage = (userCheckYesCount / totalAssets) * 100;

  return (
    <Box p={2}>
      {/* ส่วนหัวของตาราง */}
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h6">No Check Assets</Typography>
        <Box display="flex" alignItems="center">
          <WarningIcon color="error" />
          <Typography variant="body1" style={{ marginLeft: '8px' }}>
            Total No Check: {assets.length}
          </Typography>
        </Box>
      </Box>

      {/* ช่องค้นหา */}
      <Box mb={2}>
        <SearchAndScan onSearch={handleSearch} />
      </Box>

      {/* แสดง Progress เป็นวงกลม */}
      <Box my={4} display="flex" justifyContent="center" alignItems="center">
        <Box width="150px" height="150px">
          <CircularProgressbar
            value={userCheckPercentage}
            text={`${userCheckPercentage.toFixed(2)}%`}
            styles={buildStyles({
              textSize: '16px',
              pathColor: '#4caf50',
              textColor: '#000',
              trailColor: '#d6d6d6',
            })}
          />
        </Box>
        <Box ml={4}>
          <Typography variant="h6">
            {userCheckYesCount}/{totalAssets} assets checked
          </Typography>
        </Box>
      </Box>

      {/* DataGrid */}
      <div className="table-container">
        <DataGrid
          apiRef={apiRef}
          rows={assets}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
          pagination
          slots={{ toolbar: CustomToolbar }}
          onRowClick={handleRowClick}
          autoHeight={false}
          disableExtendRowFullWidth={true}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#f0f0f0',
            },
            '& .MuiDataGrid-root': {
              overflowX: 'auto',
            },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'nowrap',
            },
            '& .MuiDataGrid-row:nth-of-type(odd)': {
              backgroundColor: '#f9f9f9',
            },
            '& .MuiDataGrid-row:nth-of-type(even)': {
              backgroundColor: '#ffffff',
            },
          }}
        />
      </div>

      {/* CSS สำหรับ responsive height */}
      <style jsx>{`
        .table-container {
          height: 700px;
        }

        @media (max-width: 768px) {
          .table-container {
            height: 560px;
          }

          .circular-progress-bar {
            width: 100px;
            height: 100px;
          }
        }
      `}</style>
    </Box>
  );
}
