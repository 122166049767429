import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField
} from '@mui/material';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

const CompanyList = () => {
  const [companies, setCompanies] = useState([]);
  const [open, setOpen] = useState(false);
  const [editingCompany, setEditingCompany] = useState(null); // Store company being edited
  const [newCompany, setNewCompany] = useState({
    name: '',
    address: '',
    phone: '',
    email: '',
    abbreviation: '',
    tax_no: '',
    map: ''
  });

  // Fetch company data from the API
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get('https://dbaac.dodaydream.net/api/companies', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setCompanies(response.data);
      } catch (err) {
        console.error('Failed to fetch companies', err);
      }
    };
    fetchCompanies();
  }, []);

  // Handle opening the dialog for editing/adding companies
  const handleClickOpen = (company = null) => {
    setEditingCompany(company);
    setNewCompany(company || {
      name: '',
      address: '',
      phone: '',
      email: '',
      abbreviation: '',
      tax_no: '',
      map: ''
    });
    setOpen(true);
  };

  // Handle closing the dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Handle form submission (either editing or adding)
  const handleSubmit = async () => {
    if (editingCompany) {
      // Update company
      try {
        await axios.put(`https://dbaac.dodaydream.net/api/companies/${editingCompany.id}`, newCompany, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setCompanies(companies.map(company =>
          company.id === editingCompany.id ? { ...company, ...newCompany } : company
        ));
      } catch (err) {
        console.error('Failed to update company', err);
      }
    } else {
      // Add new company
      try {
        const response = await axios.post('https://dbaac.dodaydream.net/api/companies', newCompany, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setCompanies([...companies, { ...newCompany, id: response.data.id }]);
      } catch (err) {
        console.error('Failed to add company', err);
      }
    }
    handleClose();
  };

  // Handle deleting a company
  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://dbaac.dodaydream.net/api/companies/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setCompanies(companies.filter(company => company.id !== id));
    } catch (err) {
      console.error('Failed to delete company', err);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Abbreviation</TableCell>
            <TableCell>Tax No</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companies.map(company => (
            <TableRow key={company.id}>
              <TableCell>{company.name}</TableCell>
              <TableCell>{company.abbreviation}</TableCell>
              <TableCell>{company.tax_no}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleClickOpen(company)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDelete(company.id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => handleClickOpen()}>
        Add New Company
      </Button>

      {/* Dialog for adding/editing company */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingCompany ? 'Edit Company' : 'Add New Company'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            fullWidth
            value={newCompany.name}
            onChange={(e) => setNewCompany({ ...newCompany, name: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Abbreviation"
            fullWidth
            value={newCompany.abbreviation}
            onChange={(e) => setNewCompany({ ...newCompany, abbreviation: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Tax No"
            fullWidth
            value={newCompany.tax_no}
            onChange={(e) => setNewCompany({ ...newCompany, tax_no: e.target.value })}
          />
          {/* Add other fields for address, phone, etc., as needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {editingCompany ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
};

export default CompanyList;
