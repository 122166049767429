import React, { useState, useEffect } from 'react';
import { TextField, Button, Paper, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const CompanyForm = () => {
  const [company, setCompany] = useState({
    name: '',
    abbreviation: '',
    address: '',
    phone: '',
    email: '',
    tax_no: '',
    map: ''
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { id } = useParams(); // ดึง id จากพารามิเตอร์ URL

  useEffect(() => {
    if (id) {
      const fetchCompany = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`https://aac.dodaydream.net/api/companies/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setCompany(response.data);
        } catch (err) {
          setError('Failed to fetch company details');
        }
      };
      fetchCompany();
    }
  }, [id]);

  const handleChange = (e) => {
    setCompany({ ...company, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      if (id) {
        await axios.put(`https://aac.dodaydream.net/api/companies/${id}`, company, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      } else {
        await axios.post('https://aac.dodaydream.net/api/companies', company, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      }
      navigate('/companies');
    } catch (err) {
      setError('Failed to save company');
    }
  };

  return (
    <Paper style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>
        {id ? 'Edit Company' : 'Add New Company'}
      </Typography>
      {error && <p>{error}</p>}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Company Name"
          name="name"
          value={company.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Abbreviation"
          name="abbreviation"
          value={company.abbreviation}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Tax No"
          name="tax_no"
          value={company.tax_no}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Address"
          name="address"
          value={company.address}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Phone"
          name="phone"
          value={company.phone}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          value={company.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Map"
          name="map"
          value={company.map}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" type="submit">
          {id ? 'Update Company' : 'Add Company'}
        </Button>
      </form>
    </Paper>
  );
};

export default CompanyForm;
