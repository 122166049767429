// Footer.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box } from '@mui/material';

const Footer = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ position: 'fixed', bottom: 0, width: '100%', textAlign: 'center', backgroundColor: '#f8f8f8', padding: '10px' }}>
      <Button variant="contained" color="primary" onClick={() => navigate(-1)} style={{ marginRight: '10px' }}>
        Back
      </Button>
      <Button variant="contained" color="secondary" onClick={() => navigate(1)}>
        Next
      </Button>
    </Box>
  );
};

export default Footer;
