import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DataGrid,
  GridToolbarContainer,
  useGridApiContext,
  useGridApiRef,
} from '@mui/x-data-grid';
import { createSvgIcon } from '@mui/material/utils';
import { Button, Box, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import * as XLSX from 'xlsx';
import SearchAndScan from './SearchAndScan';

// Create an icon for the export button
const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  'SaveAlt',
);

// Custom Toolbar for CSV and XLSX export
function CustomToolbar() {
  const apiRef = useGridApiContext();

  // Function to export CSV
  const handleExportCSV = () => {
    const visibleColumns = apiRef.current.getVisibleColumns();
    const rows = Array.from(apiRef.current.getRowModels().values());

    // Create CSV content
    const csvContent = [
      '\uFEFF', // UTF-8 BOM for proper encoding
      visibleColumns.map((col) => col.field).join(','),
      ...rows.map((row) =>
        visibleColumns.map((col) => row[col.field]).join(',')
      ),
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Function to export XLSX
  const handleExportXLSX = () => {
    const visibleColumns = apiRef.current.getVisibleColumns();
    const rows = Array.from(apiRef.current.getRowModels().values());

    const dataToExport = rows.map((row) =>
      visibleColumns.reduce((acc, col) => {
        acc[col.field] = row[col.field];
        return acc;
      }, {})
    );

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'export.xlsx');
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" size="small" startIcon={<ExportIcon />} onClick={handleExportCSV}>
        Export CSV
      </Button>
      <Button color="primary" size="small" startIcon={<ExportIcon />} onClick={handleExportXLSX}>
        Export XLSX
      </Button>
    </GridToolbarContainer>
  );
}

export default function NoCheckAssets() {
  const [assets, setAssets] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userCheckYesCount, setUserCheckYesCount] = useState(0);
  const apiRef = useGridApiRef();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [assetsResponse, companiesResponse] = await Promise.all([
          fetchWithToken('https://dbaac.dodaydream.net/api/fixed_assets/all'),
          fetchWithToken('https://dbaac.dodaydream.net/api/companies'),
        ]);

        if (!assetsResponse.ok || !companiesResponse.ok) {
          throw new Error('Network response was not ok');
        }

        const assetsJson = await assetsResponse.json();
        const companiesJson = await companiesResponse.json();

        setCompanies(companiesJson);

        const companyIdToDetails = {};
        companiesJson.forEach((company) => {
          companyIdToDetails[company.company_id] = {
            org: company.org || '',
            orgname: company.orgname || '',
            orgname_th: company.orgname_th || '',
          };
        });

        const processedAssets = assetsJson
          .filter(asset => asset.usercheck === 'Yes') // Filter to show only assets with usercheck = Yes
          .map((asset, index) => {
            const companyDetails = companyIdToDetails[asset.Company] || {
              org: 'Unknown',
              orgname: 'Unknown',
              orgname_th: 'Unknown',
            };

            return {
              ...asset,
              org: companyDetails.org,
              orgname: companyDetails.orgname,
              orgname_th: companyDetails.orgname_th,
              id: asset.id || asset.Inventory_ID || `${asset.Company}-${index}`,
            };
          });

        setAssets(processedAssets);

        const yesCheckCount = processedAssets.length;
        setUserCheckYesCount(yesCheckCount);

        let dynamicColumns = [];
        if (processedAssets.length > 0) {
          dynamicColumns = [
            { field: 'org', headerName: 'Org', minWidth: 150, flex: 1, sortable: true },
            { field: 'orgname', headerName: 'Orgname', minWidth: 150, flex: 1, sortable: true },
            { field: 'orgname_th', headerName: 'Orgname Th', minWidth: 150, flex: 1, sortable: true },
          ];

          const otherKeys = Object.keys(processedAssets[0]).filter(
            key => !['org', 'orgname', 'orgname_th', 'Company'].includes(key)
          );

          otherKeys.forEach((key) => {
            dynamicColumns.push({
              field: key,
              headerName: key.replace(/_/g, ' '),
              minWidth: 150,
              flex: key.length > 10 ? 2 : 1,
              sortable: true,
            });
          });
        }

        setColumns(dynamicColumns);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchWithToken = async (url, options = {}) => {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('Authentication token is missing.');

      const headers = {
        Authorization: `Bearer ${token}`,
        ...options.headers,
      };

      const response = await fetch(url, { ...options, headers });
      return response;
    };

    fetchData();
  }, []);

  const handleRowClick = (params) => {
    navigate(`/asset/${params.id}`);
  };

  const handleSearch = (searchTerm) => {
    if (searchTerm) {
      const filtered = assets.filter(asset =>
        asset.Inventory_ID?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        asset.Inventory_ID_OLD?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setAssets(filtered);
    } else {
      setAssets(assets);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <Box p={2}>
      {/* Header Section */}
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h6">Checked Assets</Typography>
        <Box display="flex" alignItems="center">
          <WarningIcon color="error" />
          <Typography variant="body1" style={{ marginLeft: '8px' }}>
            Total Checked: {userCheckYesCount}
          </Typography>
        </Box>
      </Box>

      {/* Search and Scan */}
      <Box mb={2}>
        <SearchAndScan onSearch={handleSearch} />
      </Box>

      {/* DataGrid */}
      <div className="table-container">
        <DataGrid
          apiRef={apiRef}
          rows={assets}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
          pagination
          slots={{ toolbar: CustomToolbar }}
          onRowClick={handleRowClick}
          autoHeight={false}
          disableExtendRowFullWidth={true}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#f0f0f0',
            },
            '& .MuiDataGrid-root': {
              overflowX: 'auto',
            },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'nowrap',
            },
            '& .MuiDataGrid-row:nth-of-type(odd)': {
              backgroundColor: '#f9f9f9',
            },
            '& .MuiDataGrid-row:nth-of-type(even)': {
              backgroundColor: '#ffffff',
            },
          }}
        />
      </div>

      {/* Responsive Height CSS */}
      <style jsx>{`
        .table-container {
          height: 700px;
        }

        @media (max-width: 768px) {
          .table-container {
            height: 560px;
          }
        }
      `}</style>
    </Box>
  );
}
